import React, { ReactElement, useState } from "react";
import {
  KameleonThemeProvider,
  mergeThemes,
  ZilchPresets,
} from "@kameleon-util/global-theme";
import { Script } from "gatsby";
import { ErrorBoundary } from "react-error-boundary";
import { KameleonIconContext } from "@kameleon-common/icons";
import Footer from "./Footer";
import { DevelopmentConnectionError } from "./development/development-connection-error";
import "../styles/main.scss";
import { escapeGraphQLKeywordsIn } from "../../utils/json-utils";
import Header from "./Header";
import { NavigationProvider } from "../util-components/header-provider";
import { fallBackRender, logError } from "../util-components/error-utils";
import { LayoutPropsWithChildren } from "../types/common/prop-types";

export default function Layout({
  children,
  pageContext,
}: LayoutPropsWithChildren): ReactElement {
  const [webfontLoaded, setWebfontLoaded] = useState(false);

  const { settings, header, footer } = pageContext;
  const preset =
    (ZilchPresets as any)[settings?.profile?.toneOfVoice ?? ""] ?? undefined;
  const theme = escapeGraphQLKeywordsIn(settings?.theme ?? {}, true);

  const content = (
    <>
      <Script src="/webfont.js" onLoad={(): void => setWebfontLoaded(true)} />
      {webfontLoaded && (
        <KameleonThemeProvider theme={mergeThemes(preset?.() ?? {}, theme)}>
          <NavigationProvider>
            <KameleonIconContext.Provider value={settings?.theme?.fonts?.icons}>
              <Header {...header} />
              <main className="zilch--layout">{children}</main>
              <Footer {...footer} />
            </KameleonIconContext.Provider>
          </NavigationProvider>
        </KameleonThemeProvider>
      )}
    </>
  );

  return (
    <DevelopmentConnectionError>
      {process.env.NODE_ENV !== "production" ? (
        <ErrorBoundary fallbackRender={fallBackRender} onError={logError}>
          {content}
        </ErrorBoundary>
      ) : (
        content
      )}
    </DevelopmentConnectionError>
  );
}
