import React, { memo, ReactElement, Suspense } from "react";
import { isEqual } from "lodash";
import { DynamicLoaderProps } from "./types";
import { ZilchComponentFactory } from "./zilch-component-factory";
import { ContactFormHoc } from "../components/elements/contact-form";

function DynamicLoader(props: DynamicLoaderProps): ReactElement {
  const { blockProps } = props;
  const ZilchComponent = ZilchComponentFactory(props);

  // eslint-disable-next-line no-underscore-dangle
  if (blockProps && blockProps._contactFormSettings) {
    return (
      <ContactFormHoc
        blockProps={blockProps}
        ContactFormComponent={ZilchComponent}
      />
    );
  }
  return <Suspense fallback={<div />}>{ZilchComponent}</Suspense>;
}

export default memo(DynamicLoader, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
